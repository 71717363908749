export const cardStyles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "50px",
    borderLeft: 5,
    borderColor: "#FA496E ",
    height: "500px",
  },
  contentDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  descriptionDiv: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "16px",
    color: "#000000",
    maxWidth: "80%",
  },
  cardDiv: {
    display: "flex",
    width: "350px",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.5);",
    borderRadius: "2%",
  },
  nameDiv: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "38px",
    lineHeight: "31px",
    color: "#000000",
    padding: "5px 0px 10px 0px",
  },
  tagsDiv: {
    display: "flex",
    justifyContent: "space-around",
    padding: "25px 0px 0px 0px",
    minWidth: "55%",
  },
  partTagDiv: {
    color: "white",
    fontSize: "13px",
    padding: "2px 20px 2px 20px",
    borderRadius: "14%",
    backgroundColor: "#C95656",
  },
  upvotesDiv: {},
  authorDiv: {
    fontStyle: "italic",
  },
  bottomCardDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "80%",
    padding: "20px",
  },
};
