export const addFormStyles = {
  mainCard: {
    width: "600px",
    padding: "10px",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};
