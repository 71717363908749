export const myStyle = {
  mainDiv: {
    display: "flex",
    // flexDirection: "row",
    justifyContent: "space-between",
    width: "90vw",
    margin: "auto",
  },
  leftChildDiv: {
    position: "sticky",
    top: 0,
    width: "100%",
    minHeight: "30%",
    display: "flex",
    // flexDirection: "row",
    marginTop: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  rightChildDiv: {
    width: "70%",
    height: "100%",
    display: "flex",
    paddingTop: "100px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: 3,
    borderColor: "divider",
  },
  contentInfo: {
    marginLeft: "50px",
  },
  tabStyle: {
    marginTop: "40px",
    paddingRight: "40px",
    width: "95%",
    height: "700px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3);",
      backgroundColor: "#F5F5F5",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      "&::WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: "#138DD0",
    },
  },
  toggleIcon: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    minHeight: "50%",
    fontSize: "50px",
  },
  rightChildDivToggle: {
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "100px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  leftChildDivToggle: {
    position: "sticky",
    top: 0,
    width: "100%",
    minHeight: "30%",
    display: "flex",
    // flexDirection: "row",
    marginTop: "10px",
    paddingTop: "20px",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  contentDiv: {
    display: "flex",
    justifyContent: "space-around",
    alightItems: "flex-start",
    flexDirection: "column",
  },
  formDiv: {
    display: "flex",
    justifyContent: "center",
    alightItems: "center",
    flexDirection: "column",
    width: "90vw",
    margin: "auto",
    paddingTop: "50px",
  },
  formText: {
    paddingBottom: "15px",
  },
  formInputs: {
    paddingBottom: "10px",
    width: "500px",
  },
  formInputsLastForm: {
    paddingBottom: "10px",
    width: "500px",
  },
};
