export const authFormStyles = {
  mainCard: {
    width: "400px",
    height: "400px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};
